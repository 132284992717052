/* eslint-disable no-unused-vars */
import { Cookies } from 'utils/libHelper';
import config from '../../config';
// import { AUTH_HANDLEAUTH_SUCCESS, /*AUTH_HANDLEAUTH_FAILED,*/ AUTH_LOGOUT } from 'redux/actions';
import {
	AUTH_HANDLEAUTH_SUCCESS,
	AUTH_HANDLEAUTH_FAILED,
	AUTH_LOGOUT,
	AUTH_LOGIN,
	FILEMANAGER_SEARCH_MEDIAFILE_COUNT,
	FILEMANAGER_SEARCH_MEDIAFILES_COUNT_SUCCESS,
	FILEMANAGER_SEARCH_MEDIAFILES_COUNT_FAIL,
	DOMAIN_SETTINGS,
	DOMAIN_SETTINGS_SUCCESS,
	DOMAIN_SETTINGS_FAIL,
} from 'redux/actions';

import { fetchUserData } from './usermanager';

let usermanagerAPIConf = config.usermanagerAPI;

const filemanagerAPIBaseUrl = config.filemanagerAPI.baseUrl;
const authHeaderKey = config.filemanagerAPI.authHeaderKey;

const usermanagerAPIBaseUrl = config.usermanagerAPI.baseUrl;
const authHeaderKeyUserManager = config.usermanagerAPI.authHeaderKey;
// const promises = [];

export const userLogout = (cb) => (dispatch, getState) => {
	// TODO: Need a proper logout handler when replacing the whole lasso app
	dispatch({
		type: AUTH_LOGOUT,
	});

	if (typeof cb === 'function') cb();
};

// // TODO: Need a proper logout handler when replacing the whole lasso app
// export const userLogin = (opts, cb) => (dispatch, getState) => {
// 	console.log('@@', opts);
// 	dispatch({
// 		type: AUTH_HANDLEAUTH_SUCCESS,
// 		result: { accessToken: opts.accessToken },
// 	});
// 	if (typeof cb === 'function') cb();
// };

export const userLoginAuth = (opts, cb) => (dispatch, getState) => {
	let reqUrl = `${usermanagerAPIConf.baseUrl}/login?${usermanagerAPIConf.apiKeyName}=${usermanagerAPIConf.apiKeyValue}`;
	let axiosConfig = {
		url: reqUrl,
		method: 'post',
		data: opts.reqData,
		// headers: reqHeader,
		timeout: 30000,
	};
	return dispatch({
		// types: AUTH_LOGIN,
		types: [AUTH_LOGIN, AUTH_HANDLEAUTH_SUCCESS, AUTH_HANDLEAUTH_FAILED],
		promise: (client) => {
			return client.request(axiosConfig);
		},
		afterSuccess: ({ dispatch, getState, result, error }) => {
			if (typeof cb === 'function') cb({ dispatch, getState, result, error });
			if (!error) {
				postAuthProcess(dispatch, getState);
			}
		},
	});
};

export const handleAuth = (opts, cb) => (dispatch, getState) => {
	let accessToken = Cookies.get(config.authCookieKey) || localStorage.getItem(config.authCookieKey);
	let reduxState = getState();
	if (accessToken && reduxState.authentication.isLoggedIn) {
		dispatch({
			type: AUTH_HANDLEAUTH_SUCCESS,
			result: { data: { accessToken } },
		});
		postAuthProcess(dispatch, getState);
	} else {
		dispatch({
			type: AUTH_HANDLEAUTH_FAILED,
			error: new Error('User not authenticated'),
		});
		if (typeof cb === 'function') cb(new Error('User not authenticated'));
	}
};

const postAuthProcess = async (dispatch, getState) => {
	Promise.allSettled([
		searchFileManagerMediafiles(dispatch, getState),
		getDomainSettings(dispatch, getState),
		fetchUserData()(dispatch, getState),
	]).then((result) => console.debug(result));
};

const searchFileManagerMediafiles = async (dispatch, getState) => {
	var dateFrom = new Date(new Date());
	dateFrom.setUTCHours(0, 0, 0, 0); // START TIME of the day
	var dateTo = new Date(new Date());
	dateTo.setUTCHours(23, 59, 59, 999); // END time of the day
	let reqUrl = `${filemanagerAPIBaseUrl}/mediafiles`;
	const authState = getState().authentication;
	let reqHeader = {
		'Content-Type': 'application/json',
		[authHeaderKey]: authState.token,
	};
	let filters = {
		createdDatetimeFrom: dateFrom.toISOString(),
		createdDatetimeTo: dateTo.toISOString(),
		createdByUIDs: [getState().authentication.userId],
	};
	let axiosConfig = {
		url: reqUrl,
		method: 'post',
		headers: reqHeader,
		params: {
			domain: authState.domainName,
			uid: authState.userId,
			// ...(opts.queryParams || {}),
		},
		data: { filters: filters },
		timeout: 90000,
	};

	return new Promise((res, rej) => {
		dispatch({
			types: [
				FILEMANAGER_SEARCH_MEDIAFILE_COUNT,
				FILEMANAGER_SEARCH_MEDIAFILES_COUNT_SUCCESS,
				FILEMANAGER_SEARCH_MEDIAFILES_COUNT_FAIL,
			],
			promise: (client) => {
				return client.request(axiosConfig);
			},
			// afterSuccess: ({ dispatch, getState, result, error }) => {
			// 	if (error) return rej(error);
			// 	else {
			// 		res(result);
			// 	}
			// },
		});
	});
};

const getDomainSettings = async (dispatch, getState) => {
	let reduxState = getState();

	// let reqUrl = `${filemanagerAPIBaseUrl}/mediafiles`;
	let reqUrl = `${usermanagerAPIBaseUrl}/domains/${reduxState.authentication.domainName}/settings`;
	const authState = getState().authentication;
	let reqHeader = {
		'Content-Type': 'application/json',
		[authHeaderKey]: authState.token,
	};
	let axiosConfig = {
		url: reqUrl,
		method: 'get',
		headers: reqHeader,
		params: {
			domain: authState.domainName,
			uid: authState.userId,
			// ...(opts.queryParams || {}),
		},
		// data: { filters: filters },
		timeout: 90000,
	};

	return new Promise((res, rej) => {
		dispatch({
			types: [DOMAIN_SETTINGS, DOMAIN_SETTINGS_SUCCESS, DOMAIN_SETTINGS_FAIL],
			promise: (client) => {
				return client.request(axiosConfig);
			},
			afterSuccess: ({ dispatch, getState, result, error }) => {
				if (error) return rej(error);
				else {
					res(result);
				}
			},
		});
	});
};

// export const handleAuth = _handleAuth;

// let logout = (cb) => (dispatch, getState) => {
// 	dispatch({
// 		type: AUTH_LOGOUT
// 	});
// 	if(typeof cb === 'function')
// 		cb();
// }
// export const AUTH_LOGOUT = 'AUTH_LOGOUT';
// export const authLogout = logout;
