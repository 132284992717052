import React from 'react';

import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';

import { Avatar, Grid, Link, Paper, Typography } from '@mui/material';
import { Radio as StartleIcon } from '@mui/icons-material';
import screenIcon from './screenicon.png';

import { FormattedMessage } from 'react-intl';

// CSS
import ScreenManagerStyle from './ScreenManagerStyle.jsx';

// redux
import { connect } from 'react-redux';
import {
	fetchScreenManagerScreens,
	fetchScreenManagerPlaylists,
	fetchScreenManagerDepartments,
} from 'redux/actions'; // actions

// Constants
import {
	ROUTES_PATH_SCREENMANAGER_SCREENS,
	ROUTES_PATH_SCREENMANAGER_PLAYLISTS,
	ROUTES_PATH_SCREENMANAGER_DEPARTMENTS,
	// ROUTES_PATH_SCREENMANAGER_SCHEDULES,
	ROUTES_PATH_SCREENMANAGER_SCREENBOARD,
	ROUTES_PATH_ADMIN_PLAYER_UNIVERSE,
} from 'routes';

import { screenManagerRoutesByPath } from '../../Routes.jsx';
// const subRoutesJSON = _.find(dashboardRoutes, route=>route.key===ROUTES_SCREENMANAGER).subRoutes.reduce((result, subRoute)=>{
// 	result[subRoute.key] = subRoute;
// 	return result;
// }, {});

const pageComps = [
	{
		navPath: ROUTES_PATH_SCREENMANAGER_SCREENS,
		name: <FormattedMessage id="pages.ScreenManager.components.ScreenManager.ScreensNavName" />,
		props: {},
	},
	{
		navPath: ROUTES_PATH_SCREENMANAGER_PLAYLISTS,
		name: <FormattedMessage id="pages.ScreenManager.components.ScreenManager.PlaylistsNavName" />,
		props: {},
	},
	{
		navPath: ROUTES_PATH_SCREENMANAGER_DEPARTMENTS,
		name: <FormattedMessage id="pages.ScreenManager.components.ScreenManager.DepartmentsNavName" />,
		props: {},
	},
	// {
	// 	navPath: ROUTES_PATH_SCREENMANAGER_SCHEDULES,
	// 	name: <FormattedMessage id="pages.ScreenManager.components.ScreenManager.SchedulesNavName" />,
	// 	props: {},
	// },
	{
		// we temporarily place startle login link here,
		// it replaces the non-used screen-board link
		navPath: 'STARTLE_LOGIN',
		name: <FormattedMessage id="pages.ScreenManager.components.ScreenManager.StartleLoginName" />,
		props: {},
	},
	{
		navPath: ROUTES_PATH_SCREENMANAGER_SCREENBOARD,
		name: <FormattedMessage id="pages.ScreenManager.components.ScreenManager.ScreenboardNavName" />,
		props: {},
	},
	{
		navPath: ROUTES_PATH_ADMIN_PLAYER_UNIVERSE,
		name: (
			<FormattedMessage id="pages.ScreenManager.components.ScreenManager.PlayerUniverseNavName" />
		),
		props: {},
	},
];

class ScreenManager extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			// historyNotifications: [],
			// historyViews: ['ScreenManager'],
		};
	}

	render() {
		const { classes, authentication, startleLoginUrl } = this.props;
		let spacing = 3;
		return (
			<div className={classes.contentWrapper}>
				<Grid
					container
					direction="row"
					justifyContent="center"
					alignItems="center"
					alignContent="center" // 'space-around'
					spacing={spacing}
				>
					{pageComps.map((inPageNav, key) => {
						// we temporarily place startle login link in this page
						// the startle login link is not in our routing list, we can't validate the navPath
						// all we need to check is if the startleLoginUrl has value or not
						if (inPageNav.navPath === 'STARTLE_LOGIN') {
							if (!startleLoginUrl) return null;
							return (
								<Grid item xs={12} sm={6} md={4} key={key}>
									{
										<Link
											href={startleLoginUrl}
											rel="noreferrer"
											target="_blank"
											underline="none"
											// className={classes.itemLink}
										>
											<Paper className={classes.paperContainer}>
												<img src={screenIcon} alt="screen icon" />
												<Avatar className={classes.avatar}>
													{<StartleIcon className={classes.icon} />}
												</Avatar>
												<Typography variant="h5" color="primary" className={classes.sectionText}>
													{inPageNav.name}
												</Typography>
											</Paper>
										</Link>
									}
								</Grid>
							);
						}

						// below is for screen link
						const routeSetting = screenManagerRoutesByPath[inPageNav.navPath];
						if (
							!authentication.isAuthorized(
								routeSetting.requiredUserLevel,
								routeSetting.requiredFeatures
							)
						) {
							// Login user does not have permission to access this route
							return null;
						}
						let AvatarIcon = routeSetting.sidebarIcon,
							toLink = inPageNav.navPath;
						return (
							<Grid item xs={12} sm={6} md={4} key={key}>
								{
									<RouterLink to={toLink} className={classes.routeLink}>
										<Paper className={classes.paperContainer}>
											<img src={screenIcon} alt="screen icon" />
											<Avatar className={classes.avatar}>
												{<AvatarIcon className={classes.icon} />}
											</Avatar>
											<Typography variant="h5" color="primary" className={classes.sectionText}>
												{inPageNav.name}
											</Typography>
										</Paper>
									</RouterLink>
								}
							</Grid>
						);
					})}
				</Grid>
				{authentication.userLevel > 30 && (
					<p>
						Download apk
						<Link href="https://vid-mediafiles-ie.s3-eu-west-1.amazonaws.com/ViDPlayer_ARM_Latest.apk">
							{' '}
							(ARM)
						</Link>
						<Link href="https://vid-mediafiles-ie.s3-eu-west-1.amazonaws.com/ViDPlayer_ARM64_Latest.apk">
							(ARM64)
						</Link>
					</p>
				)}
			</div>
		);
	}
}

ScreenManager.propTypes = {
	classes: PropTypes.object.isRequired,
	authentication: PropTypes.object.isRequired,
	startleLoginUrl: PropTypes.string,
};

ScreenManager.defaultProps = {
	// multiple: false,
	// allowZip: false,
	// allowPreview: true,
	// disableClick: false,
};

const mapStateToProps = (state) => {
	return {
		authentication: state.authentication,
		startleLoginUrl: state.usermanager.userData.startleLoginUrl,
	};
};

export default connect(mapStateToProps, {
	fetchScreenManagerScreens,
	fetchScreenManagerPlaylists,
	fetchScreenManagerDepartments,
})(withStyles(ScreenManagerStyle, { withTheme: true })(ScreenManager));
